import cx from 'classnames';
import Spinner from './Spinner';

type LoaderProps = {
  isFullHeight?: boolean;
};

function Loader({ isFullHeight = false }: LoaderProps) {
  return (
    <div
      className={cx(
        'grid place-items-center',
        isFullHeight ? 'w-screen h-screen' : 'w-full',
      )}
    >
      <div className="text-center">
        {isFullHeight && <img src="/logo.png" className="max-h-20" alt="logo" /> }
        <p className="text-main flex items-center mt-4 justify-center">
          <Spinner className="mr-1 text-primary" size="md" />
        </p>
      </div>
    </div>
  );
}

Loader.defaultProps = {
  isFullHeight: false,
};

export default Loader;
