import { ReactNode, useEffect, useReducer } from 'react';

import {
  AuthContext, AuthContextInterface, DevicePaymentMethod, Store,
} from './AuthContext';
import { initialState, reducer, types } from './AuthReducer';

import useIsSignedIn from '@/api/authentication';
import Loader from '@/components/Loader';
import storage, { storageItem } from '@/utils/storage';

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isSignedIn = useIsSignedIn();

  const parseStore = (store: Store) => ({
    token: storage.getToken(storageItem.token),
    paymentMethods: store?.paymentMethods,
    devicePaymentMethods: storage.getTokenAsObject(storageItem.paymentMethods),
  });

  useEffect(() => {
    if (isSignedIn?.data?.data?.store && isSignedIn.isLoading === false) {
      dispatch({ type: types.handleLogin, payload: { store: parseStore(isSignedIn?.data?.data?.store as never) } });
      return;
    }
    if (!isSignedIn?.data?.data?.store && isSignedIn.isLoading === false) {
      dispatch({ type: types.handleLogout });
    }
  }, [isSignedIn?.data, isSignedIn.isLoading]);

  const handleLogin = (store: Store) => {
    dispatch({ type: types.handleLogin, payload: parseStore(store) });
  };

  const handleLogout = () => {
    storage.clear();
  };

  const handleSetDevicePaymentMethods = (paymentMethods: DevicePaymentMethod[]) => {
    dispatch({ type: types.setDevicePaymentMethods, payload: paymentMethods });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValues: AuthContextInterface = {
    ...state,
    store: state.store,
    setDevicePaymentMethods: handleSetDevicePaymentMethods,
    handleLogin,
    handleLogout,
  };
  if (state.isLoading === 'idle' || isSignedIn.isLoading === true) {
    return <Loader isFullHeight />;
  }

  return (
    <AuthContext.Provider value={providerValues}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
