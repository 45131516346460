import Btn from './Btn';

function ErrorFallback() {
  return (
    <div
      className="w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <img src="/logo.png" className="w-40" alt="app-logo" />
      <h2 className="text-gray-800 text-lg font-semibold mt-3 mb-6">Oops, algo salió mal...</h2>
      <Btn className="btn-outline" onClick={() => window.location.assign(window.location.origin)}>
        Recargar
      </Btn>
    </div>
  );
}

export default ErrorFallback;
