import cx from 'classnames';

type BtnProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

function BtnList({ children, onClick }: BtnProps) {
  return (
    <button
      className={cx('btn-list')}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

BtnList.defaultProps = {
  onClick: () => {},
};

export default BtnList;
