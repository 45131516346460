export default function formatMoney(amount: number): string {
  // the money amount is in cents, so we need to divide by 100
  const amountToFormat: number = amount / 100;
  const formattedAmount: string = amountToFormat.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1,
    useGrouping: true,

  });
  return `${formattedAmount}`;
}
