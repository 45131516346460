import toast from 'react-hot-toast';


import { mutateOrder } from '@/api/orders';
import Btn from '@/components/Btn';
import useAuth from '@/hooks/useAuth';
import useCart from '@/hooks/useCart';
import useCashKeeper from '@/hooks/useCashKeeper';
import { ImCoinEuro } from 'react-icons/im';

type CashKeeperPaymentProps = {
  onClick: () => void;
};

const CashKeeperPayment = ({onClick} : CashKeeperPaymentProps) => {
  const { store } = useAuth();
  const { hasConnection, handleStartPayment, cashKeeperConnectionStatus } = useCashKeeper();

  const isCashKeeperEnabled = store?.devicePaymentMethods?.cashKeeperPayment === true;

  const {
    cart, orderTotal, clearCart
  } = useCart();

  const mutate = mutateOrder();

  const handleMakeOrder = async () => {
    onClick();
    mutate.mutateAsync({
      cartItems: cart,
      cartTotal: orderTotal,
      cartSubTotal: orderTotal,
      cartDiscount: 0,
      paymentMethod: "cashKeeper"
    }).then((res) => {
      handleStartPayment(res.data, orderTotal);
      clearCart();
    }).catch(() => {
      toast.error('Error al hacer el pedido');
    });
  };

  if(!isCashKeeperEnabled || !hasConnection) return <></>;

  return (
    <Btn
      className='h-44 p-10 flex flex-col items-center justify-center gap-2 text-2xl'
      onClick={handleMakeOrder}
      isProcessing={mutate.isLoading}
      disabled={cashKeeperConnectionStatus === 'disconnected'}
    >
      <span>
        <ImCoinEuro className="text-6xl" />
      </span>
      Pagar en efectivo
    </Btn>
  );
}

export default CashKeeperPayment;