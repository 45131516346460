import { Link, useLocation, useNavigate } from 'react-router-dom';

import { MdArrowBackIosNew, MdOutlineShoppingCart } from 'react-icons/md';

import useCart from '@/hooks/useCart';
import Btn from './Btn';

function NavBar() {
  const location = useLocation();
  const hasHomeLink = ['/cart', '/settings'].includes(location.pathname);
  const hasCartButton = location.pathname === '/';
  const navigate = useNavigate();
  const { cartItems } = useCart();

  const hasBackButton = ['/', '/checkout', '/cart'].includes(location.pathname);

  const handleCartClick = () => {
    navigate('/cart', { replace: true });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="bg-primary h-16 flex justify-between items-center px-5">
      <div className="min-w-20">
        {!hasBackButton && (
          <Btn className="outline" onClick={handleBackClick}>
            <span className="sr-only">Back</span>
            <MdArrowBackIosNew className="text-2xl text-white" />
          </Btn>
        )}
      </div>
      <div className="">
        <Link to={hasHomeLink ? '/' : '/'}>
          <img src="/favicon.png" alt="logo" className="w-11 h-11" />
        </Link>
      </div>
      <div className="min-w-20">
        {(hasCartButton && cartItems > 0) && (
          <Btn className="outline relative" onClick={handleCartClick}>
            <span className="-top-2 -left-3 absolute grid place-items-center bg-red-600 rounded-full w-6 h-6 text-sm">
              {cartItems}
            </span>
            <MdOutlineShoppingCart className="text-2xl text-white" />
          </Btn>
        )}
      </div>
    </div>
  );
}

export default NavBar;
