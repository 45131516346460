import { Navigate } from 'react-router-dom';

import TokenPage from '@/pages/TokenPage';

const publicRoutes = [
  {
    path: '/token',
    element: <TokenPage />,
  },
  {
    path: '*',
    element: <Navigate to="/token" />,
  },
];

export default publicRoutes;
