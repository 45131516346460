/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import cx from 'classnames';

type Props = {
  value?: string;
  checked: boolean | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
};

function Toggle({
  value, checked, onChange, label, className, disabled,
}: Props) {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    onChange(e);
  };

  return (
    <div className={cx('w-full', className, { 'opacity-50': disabled })}>
      <label className=" inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={checked} value={value} className="sr-only peer" onChange={handleOnChange} />
        <div className="relative min-w-[2.5rem] w-10 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-orange-500" />
        <span className="ml-3 text-sm font-medium text-gray-900 ">{label}</span>
      </label>
    </div>
  );
}

Toggle.defaultProps = {
  className: '',
  label: '',
  value: '',
  disabled: false,
};

export default Toggle;
