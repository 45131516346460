import { useState } from 'react';
import toast from 'react-hot-toast';

import { MdOutlineDelete } from 'react-icons/md';

import Btn from '@/components/Btn';
import useCart from '@/hooks/useCart';

type ProductModalDeleteProps = {
  productId: string;
};

function ProductModalDelete({ productId }: ProductModalDeleteProps) {
  const {
    removeItemFromCart,
  } = useCart();
  const [showModal, setShowModal] = useState(false);

  const handleDelete = () => {
    removeItemFromCart(productId);
    toast.error('Producto eliminado del carrito');
  };

  return (
    <>
      <Btn
        className="bg-red-500 hover:bg-red-700"
        onClick={() => setShowModal(true)}
      >
        Eliminar
        <MdOutlineDelete className="text-xl" />
      </Btn>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Eliminar del carrito
                  </h3>
                  <button
                    type="button"
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    ¿Estás seguro que deseas eliminar este producto del carrito?
                  </p>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </button>

                  <Btn onClick={handleDelete}>
                    Eliminar
                  </Btn>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
}

export default ProductModalDelete;
