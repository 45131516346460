/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import toast from 'react-hot-toast';

import storage, { storageItem } from '@/utils/storage';

function CashKeeperForm() {
  const cashKeeperPaymentConfig = storage.getTokenAsObject(storageItem.cashKeeperPaymentConfig);
  const windowWithElectron = window as WindowWithElectron;

  const [connectionInfo, setConnectionInfo] = useState({
    ip: cashKeeperPaymentConfig?.ip as string ?? '',
    port: cashKeeperPaymentConfig?.port as string ?? '',
  });

  const handleSaveConnectionInfo = () => {
    storage.setToken(storageItem.cashKeeperPaymentConfig, connectionInfo);
    if (windowWithElectron?.electronAPI?.saveDeviceConnection) {
      windowWithElectron.electronAPI.saveDeviceConnection(connectionInfo);
    }
    toast.success('Datos guardados correctamente');
  };

  return (
    <div>
      <p className="mb-2">Ingresá la dirección IP del Cashkeeper:</p>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="floating_ip"
          id="floating_ip"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={connectionInfo?.ip ?? ''}
          onChange={(e) => setConnectionInfo({ ...connectionInfo, ip: e.target.value })}
        />
        <label
          htmlFor="floating_ip"
          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          IP Address
        </label>
      </div>
      <p className="mb-2">Ingresá el puerto del Cashkeeper:</p>
      <div className="relative z-0 w-full mb-5 group">
        <input
          type="text"
          name="floating_port"
          id="floating_port"
          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=" "
          required
          value={connectionInfo?.port ?? ''}
          onChange={(e) => setConnectionInfo({ ...connectionInfo, port: e.target.value })}
        />
        <label
          htmlFor="floating_port"
          className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
          Port
        </label>
      </div>
      <button
        type="submit"
        onClick={handleSaveConnectionInfo}
        className="bg-primary text-white rounded-md px-6 py-2 mt-2"
      >
        Guardar Datos
      </button>
    </div>
  );
}

export default CashKeeperForm;
