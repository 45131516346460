import Loader from '@/components/Loader';
import useCashKeeper from '@/hooks/useCashKeeper';

type CashKeeperStatusProps = {
  status: string;
};

const cashKeeperMsg = {
  2: 'Esperando pago',
  3: 'Realizando pago',
  4: 'Realizando pago',
  7: 'Validando pago',
};

function CashKeeperStatus() {
  const { isPaying, cashKeeperStatus } = useCashKeeper();

  if (![2, 3, 4, 7].includes(cashKeeperStatus) || !isPaying) { // 'ENABLED', 'PAYING', 'FLOATING', 'VALIDATION'
    return null;
  }

  return (
    <div className="fixed left-0 right-0 w-full h-full bg-white bg-opacity-85 flex justify-center items-center z-[99999]">
      <div className="">
        <img src="/logo.png" className="max-h-20" alt="logo" />
        <Loader />
        <div className='text-center my-5'>
          {cashKeeperMsg?.[cashKeeperStatus as never]}
        </div>
      </div>
    </div>
  );
}

export default CashKeeperStatus;
