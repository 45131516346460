import { createContext } from 'react';

export type DevicePaymentMethod = Api.PaymentMethod & {
  enabled: boolean,
  config?: Record<string, string>,
};

export type Store = {
  token: string | null;
  paymentMethods: Api.PaymentMethod[];
  devicePaymentMethods: Record<string, boolean>;
};

export interface AuthContextInterface {
  readonly isLoggedIn: 'idle' | boolean;
  store: Store;
  setDevicePaymentMethods: (paymentMethods: DevicePaymentMethod[]) => void;
  handleLogin: (token: string) => void;
  handleLogout: () => void;
}

export const authContextDefaultValues: AuthContextInterface = {
  isLoggedIn: 'idle',
  store: {
    token: null,
    paymentMethods: [],
    devicePaymentMethods: {},
  },
  setDevicePaymentMethods: () => null,
  handleLogin: () => null,
  handleLogout: () => null,
};

export const AuthContext = createContext<AuthContextInterface>(authContextDefaultValues);
