import { useEffect, useReducer, useState } from 'react';

import { mutateOrderCompleted, mutateTransaction } from '@/api/orders';
import CashKeeperStatus from '@/features/cashKeeper/CashKeeperStatus';
import CheckoutOrderNumberModal from '@/features/checkout/CheckoutOrderNumberModal';
import useDailyResetStore from '@/stores/daily-orders.store';
import handleCashKeeperCommands, { CashKeeperStatusItem, cashKeeperStatusList } from '@/utils/cashkeeperCommands';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { CashKeeperConnectionStatus, CashKeeperContext, OrderInProgress } from './CashKeeperContext';
import { initialState, reducer, types } from './CashKeeperReducer';

type CashKeeperProviderProps = {
  children: React.ReactNode;
};

function CashKeeperProvider({ children }: CashKeeperProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const windowWithElectron = window as WindowWithElectron;
  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [order, setOrder] = useState<Api.Order | null>(null);
  const { store } = useAuth();
  const navigate = useNavigate();

  const addOrder = useDailyResetStore((state) => state.addOrder);
  const orderExists = useDailyResetStore((state) => state.orderExists);

  const makeTransaction = mutateTransaction();
  const makeOrderCompleted = mutateOrderCompleted();

  const handleCommand = (deviceData: string) => {
    const { command, data } = handleCashKeeperCommands(deviceData);
    if (command === 106) {
      if (Object.keys(cashKeeperStatusList).includes(data[0].toString())) {
        dispatch({ type: types.setCashKeeperStatus, payload: data[0] });
        if(data[0] === 1 && state.orderInProgress?.orderId) {
          makeOrderCompleted.mutateAsync(state.orderInProgress?.orderId).finally(() => {
            navigate('/');
            if(!orderExists(state.orderInProgress?.orderId)) {
              setOrder(state.orderInProgress?.orderDetails);
              setTimeout(() => {
                setOrderNumber(state.orderInProgress?.orderNumber);
                dispatch({ type: types.handleEndPayment });
              }, 200);
              addOrder(state.orderInProgress?.orderId, state.orderInProgress?.orderDetails);
            }
          });
        }
      }
    }
    if(command === 107 && state.orderInProgress?.orderId) {
      makeTransaction.mutateAsync({ orderId: state.orderInProgress?.orderId, payment: parseInt(data[0] as string) });
    }
    if(command === 100) {
    }
  }

  useEffect(() => {
    (async () => {
      const isCashKeeperEnabled = store?.devicePaymentMethods?.cashKeeperPayment === true;
      if (isCashKeeperEnabled && windowWithElectron?.electronAPI?.checkDeviceConnection) {
        const isConnected = await windowWithElectron.electronAPI.checkDeviceConnection();
        if (!isConnected) {
          await windowWithElectron.electronAPI.createConnection();
          return;
        }
      }
    })();
  }, [store]);

  useEffect(() => {
    if (windowWithElectron?.electronAPI?.deviceDisconnected) {
      windowWithElectron?.electronAPI.deviceDisconnected(() => {
        dispatch({ type: types.setCashKeeperConnectionStatus, payload: 'disconnected' });
      });
    }
    if (windowWithElectron?.electronAPI?.deviceConnected) {
      windowWithElectron?.electronAPI.deviceConnected(() => {
        dispatch({ type: types.setCashKeeperConnectionStatus, payload: 'connected' });
      });
    }
    if (windowWithElectron?.electronAPI?.deviceData) {
      windowWithElectron?.electronAPI.deviceData((deviceData) => {
        if (deviceData.includes('$')) {
          const parsedMessages = deviceData.split('$').filter((m: string) => m.length > 0);
          parsedMessages.forEach((m: string) => handleCommand(m));
        }
      });
    }
  }, [store, state.orderInProgress]);

  useEffect(() => {
    const { cashKeeperConnectionStatus } = state;
    if (cashKeeperConnectionStatus === 'connected' && windowWithElectron?.electronAPI?.startCnt) {
      windowWithElectron.electronAPI.startCnt();
    }
  }, [state.cashKeeperConnectionStatus]);

  const handleSetHasConnection = (hasConnection: boolean) => {
    dispatch({ type: types.setHasConnection, payload: hasConnection });
  }

  const handleCashKeeperConnectionStatus = (status: CashKeeperConnectionStatus) => {
    dispatch({ type: types.setCashKeeperConnectionStatus, payload: status });
  }

  const handleSetCashKeeperStatus = (status: CashKeeperStatusItem) => {
    dispatch({ type: types.setCashKeeperStatus, payload: status });
  }

  const handleStartPayment = (orderInProgress: OrderInProgress, amount: number) => {
    if(windowWithElectron?.electronAPI?.totalizePayment) {
      windowWithElectron.electronAPI.totalizePayment(amount);
      dispatch({ type: types.handleStartPayment, payload: orderInProgress});
    }
  }

  const handleEndPayment = () => {
    dispatch({ type: types.handleEndPayment });
  }

  const providerValues = {
    ...state,
    hasConnection: !!windowWithElectron?.electronAPI,
    setHasConnection: handleSetHasConnection,
    cashKeeperConnectionStatus: state.cashKeeperConnectionStatus,
    setCashKeeperConnectionStatus: handleCashKeeperConnectionStatus,
    cashKeeperStatus: state.cashKeeperStatus,
    setCashKeeperStatus: handleSetCashKeeperStatus,
    isPaying: state.isPaying,
    handleStartPayment,
    handleEndPayment,
    orderInProgress: state.orderInProgress,
  };

  return (
    <CashKeeperContext.Provider value={providerValues}>
      {providerValues.cashKeeperConnectionStatus === 'disconnected' && (
        <div className="bg-red-600 text-white text-sm p-2 text-center">Error de comunicación con CashKeeper, por favor contacte al gerente.</div>
      )}
      {orderNumber && order && <CheckoutOrderNumberModal order={order} orderNumber={orderNumber} onSuccess={() => setOrderNumber(null)} isPrinting />}
      <CashKeeperStatus />
      {children}
    </CashKeeperContext.Provider>
  );
}

export default CashKeeperProvider;
