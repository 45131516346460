const storagePrefix = 'ck_app_';

type StorageItem =
  'token' |
  'cashKeeperPaymentConfig' |
  'paymentMethods' |
  'storeSecurityCode';

export const storageItem: Record<StorageItem, string> = {
  token: 'token',
  cashKeeperPaymentConfig: 'cashKeeperPaymentConfig',
  paymentMethods: 'paymentMethods',
  storeSecurityCode: 'storeSecurityCode',
};

const isValidStorageItem = (value: string) => Object.values(storageItem).indexOf(value) !== -1;

const getStorageItem = (name: string): string | null => {
  if (!isValidStorageItem(name)) {
    return null;
  }
  return localStorage.getItem(`${storagePrefix}${name}`);
};

const getStorageItemAsObject = (name: string): Record<string, unknown> | null => {
  if (!isValidStorageItem(name)) {
    return null;
  }
  const item = localStorage.getItem(`${storagePrefix}${name}`);
  if (!item) {
    return {};
  }
  return JSON.parse(item);
};

const setStorageItem = (name: string, value: string | object): void | null => {
  if (!isValidStorageItem(name)) {
    return null;
  }
  if (typeof value === 'object') {
    return localStorage.setItem(`${storagePrefix}${name}`, JSON.stringify(value));
  }
  return localStorage.setItem(`${storagePrefix}${name}`, value);
};

const removeStorageItem = (name: string): void | null => {
  if (!isValidStorageItem(name)) {
    return null;
  }
  return localStorage.removeItem(`${storagePrefix}${name}`);
};

const clearStorage = (): void => {
  localStorage.clear();
};

const storage = {
  getToken: (name: string) => getStorageItem(name),
  getTokenAsObject: (name: string) => getStorageItemAsObject(name),
  setToken: (name: string, value: string | object) => setStorageItem(name, value),
  removeToken: (name: string) => removeStorageItem(name),
  clear: () => clearStorage(),
};

export default storage;
