/* eslint-disable @typescript-eslint/comma-dangle */
import { useQuery } from 'react-query';

import { Store } from '@/context/auth/AuthContext';
import { axios } from './axios';

const getStore = () => axios.get<{ store: Api.Response<Store> }>('/store/auth');

const useIsSignedIn = () => {
  const query = useQuery('auth/me', getStore, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  return query;
};

export default useIsSignedIn;
