import { AppProvider } from '@/context/AppProvider';
import { AppRoutes } from '@/router/AppRoutes';
import AuthProvider from './context/auth/AuthProvider';
import CartProvider from './context/cart/CartProvider';
import CashKeeperProvider from './context/cashKeeper/CashKeeperProvider';

function App() {
  return (
    <AppProvider>
      <AuthProvider>
        <CartProvider>
          <CashKeeperProvider>
            <AppRoutes />
          </CashKeeperProvider>
        </CartProvider>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
