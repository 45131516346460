export type CashKeeperStatusItem = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const cashKeeperStatusList: Record<CashKeeperStatusItem, string> = {
  /*
   * Indicates that the communication ports are not open and
   * there is no active communication with the device. It is the initial state.
   * Attention, as it is a CashKeeper state, without being connected is impossible to
   * know it. Therefore, the integrator must control when is or not connected.
   * */
  0: 'ALL_CLOSED',
  /*
    * The system is in sleep mode. Communication with the device is
    * active and can be performed any action. Except for some specific commands
    * (used to make CashKeeper change of state), most of the functions must be
    * done when CashKeeper is in this state
    * */
  1: 'IDLE',
  /*
    *The system is in cash reception state
     * */
  2: 'ENABLED',
  /*
    * The system is doing a payment (notes and/or coins). During this
    * state, the system will trigger the ValueOUT event informing of the total amount
    * paid.
    * */
  3: 'PAYING',
  /*
    * The system is sending coins and / or banknotes to the
    * cashboxes. During this state, the system will trigger the ValueToCashBox event
    * reporting the total amount sent to the cashboxes.
    * */
  4: 'FLOATING',
  /*
    * The system is emptying the change containers for coins and
    * / or bills into the collection drawers. During this state, the system will trigger
    * the ValueToCashBox event reporting the total amount sent to the drawer/s.
    * */
  5: 'EMPTYING',
  /*
    * The system is in an error state and is not possible to operate
    * with it. The system acquires this status when a nonrecoverable error occurs
    * (note or coin jammed, etc.). The only exception would be the Reset function,
    * which during its execution, acquires this status to prevent the execution of
    * other commands.
    * */
  6: 'ERROR',
  /*
    * The system is verifying the introduced coins/banknotes,
    * this means that there is mechanical movement on the devices. It is in this state
    * when ValueIN events occur corresponding to validated cash
    * */
  7: 'VALIDATION',

};

const windowWithElectron = window as WindowWithElectron;

type CashKeeperResponse = {
  command: number;
  data: (string | number)[];
};

const parseCashKeeperResponse = (response: string): CashKeeperResponse => {
  // Response format is $command|data#
  const parsedResponse = response.replaceAll('$', '').replaceAll('#', '').trim().split('|');
  const data = parsedResponse.slice(1).map((d) => (!Number.isNaN(parseInt(d, 10)) ? parseInt(d, 10) : d));
  return {
    // first element is command
    command: parseInt(parsedResponse[0], 10),
    // others elements are data
    data,
  };
};

const handleCashKeeperCommands = (message: string): CashKeeperResponse => {
  if (!windowWithElectron?.electronAPI) return { command: 0, data: [0] };

  const response = parseCashKeeperResponse(message);
  switch (response.command) {
    case 42: { // Totalize
      // console.log('Totalize', response.data[0]);
    } break;
    case 57: { // CNT
      const dataFirstIsNumber = !Number.isNaN(response.data[0]);
      if (!dataFirstIsNumber) {
        return { command: response.command, data: response.data };
      }
      const dataFirst = response.data[0] as number;
      const securitySeed = 100001;
      windowWithElectron?.electronAPI?.cnt(dataFirst + securitySeed);
    } break;
    case 58: // CNT_OK
      if (response.data[0] === 5) {
        windowWithElectron?.electronAPI?.startUp();
      }
      break;
    case 100: {// Disabled
      // console.log('Disabled', 'finished asynchronous operation');
      // console.log('ErrorCode', response.data[0]);
      // console.log('ErrorDescription', response.data[1]);
      // console.log('CurrentValue', response.data[2]);
      // console.log('TargetValue', response.data[3]);
      // console.log('State', response.data[4]);
      // console.log('Operation', response.data[5]);
    } break;
    case 106: // StateChange
      // console.log('State', cashKeeperStatusList[response.data[0] as CashKeeperStatusItem]);
    case 107: {// ValueIN
      // console.log('CurrentValue', response.data[0]);
      // console.log('Target', response.data[1]);
      // console.log('Unkown', response.data[2]);
    } break;
    case 108: {// ValueOUT
      // console.log('ValueOUT', 'given change');
      // console.log('CurrentValue', response.data[0]);
      // console.log('Target', response.data[1]);
      // console.log('Unkown', response.data[2]);
    } break;
    default:
      break;
  }
  return { command: response.command, data: response.data };
};

export default handleCashKeeperCommands;
