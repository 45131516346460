import { CashKeeperStatusItem } from '@/utils/cashkeeperCommands';
import { createContext } from 'react';

export type CashKeeperConnectionStatus = 'idle' | 'connected' | 'disconnected';

export type OrderInProgress = {
  orderId: string;
  orderNumber: string;
};

export interface CashKeeperContextInterface {
  hasConnection: boolean; // means that we have electronApi available
  cashKeeperConnectionStatus: CashKeeperConnectionStatus; // means that we have connection with cashKeeper
  setCashKeeperConnectionStatus: (status: CashKeeperConnectionStatus) => void;
  cashKeeperStatus: CashKeeperStatusItem; // means the status of cashKeeper (see cashkeeperCommands.ts for more info)
  setCashKeeperStatus: (status: CashKeeperStatusItem) => void;
  isPaying: boolean;
  orderInProgress: OrderInProgress | null;
  handleStartPayment: (orderInProgress: OrderInProgress, amount: number) => void;
  handleEndPayment: () => void;
}

export const cashKeeperContextDefaultValues: CashKeeperContextInterface = {
  hasConnection: false,
  cashKeeperConnectionStatus: 'idle',
  setCashKeeperConnectionStatus: () => {},
  cashKeeperStatus: 0,
  setCashKeeperStatus: () => {},
  isPaying: false,
  handleStartPayment: () => {},
  handleEndPayment: () => {},
  orderInProgress: null,
};

export const CashKeeperContext = createContext<CashKeeperContextInterface>(cashKeeperContextDefaultValues);
