import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { MdOutlineCreditCard, MdOutlineIcecream } from 'react-icons/md';

import { mutateOrder } from '@/api/orders';
import Btn from '@/components/Btn';
import Loader from '@/components/Loader';
import CashKeeperPayment from '@/features/checkout/CashKeeperPayment';
import CheckoutOrderNumberModal from '@/features/checkout/CheckoutOrderNumberModal';
import useAuth from '@/hooks/useAuth';
import useCart from '@/hooks/useCart';
import formatMoney from '../utils/money';

function CheckoutPage() {
  const { store } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);

  const isCashKeeperEnabled = store?.devicePaymentMethods?.cashKeeperPayment === true;
  const isCashPaymentEnabled = store?.devicePaymentMethods?.cashPayment === true;
  const isCardPaymentEnabled = store?.devicePaymentMethods?.cardPayment === true;

  const [orderNumber, setOrderNumber] = useState<number | null>(null);
  const [order, setOrder] = useState<Api.Order | null>(null);
  const navigate = useNavigate();
  const {
    cart, orderTotal, cartItems, clearCart,
  } = useCart();

  const mutate = mutateOrder();

  useEffect(() => {
    if (cartItems === 0 && !isProcessing) {
      navigate('/');
    }
  }, []);

  const handleMakeOrder = async (paymentMethod: string) => {
    mutate.mutateAsync({
      cartItems: cart,
      cartTotal: orderTotal,
      cartSubTotal: orderTotal,
      cartDiscount: 0,
      paymentMethod,
    }).then((res) => {
      clearCart();
      setOrderNumber(res.data.orderNumber);
      setOrder(res.data.orderDetails);
      toast.success('Pedido realizado con exito');
    }).catch(() => {
      toast.error('Error al hacer el pedido');
    });
  };

  if (orderNumber && order) {
    return <CheckoutOrderNumberModal orderNumber={orderNumber} order={order} isPrinting />;
  }

  if(isProcessing) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-4xl text-center">Resumen del Pedido</h2>
      <h2 className="text-5xl text-center font-bold my-4">{formatMoney(orderTotal)}</h2>
      <h3 className="text-3xl text-center">
        Aparecera tu
        {' '}
        <strong className="text-primary">Numero de pedido</strong>
        {' '}
        con el podrás recogerlo en caja.
      </h3>

      <div className="flex flex-col justify-center items-center gap-8 mt-20">
        <Btn onClick={() => navigate('/')} disabled={mutate.isLoading}>
          Añadir producto
          <MdOutlineIcecream className="text-2xl" />
        </Btn>
      </div>
      <div className="flex justify-center items-center gap-8 mt-2">
        {isCashPaymentEnabled && (
          <Btn
            className='h-44 p-10 flex flex-col items-center justify-center gap-2 text-2xl'
            onClick={() => handleMakeOrder('cashPayment')} 
            isProcessing={mutate.isLoading}
            >
            <span>
              <MdOutlineCreditCard className="text-6xl" />
            </span>
            Pagar con tarjeta
          </Btn>
        )}
        <CashKeeperPayment onClick={() => setIsProcessing(true)} />
        {isCardPaymentEnabled && (
          <Btn 
          onClick={() => handleMakeOrder('cardPayment')} isProcessing={mutate.isLoading}>
            Pagar con datáfono
            <MdOutlineCreditCard className="text-2xl" />
          </Btn>
        )}
      </div>
    </div>
  );
}

export default CheckoutPage;
