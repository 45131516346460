import { useState } from 'react';

import Title from '@/components/Title';
import PaymentMethodsForm from '@/features/config/PaymentMethodsForm';
import SecurityCode from '@/features/config/SecurityCode';
import SecurityCodeValidation from '@/features/config/SecurityCodeValidation';
import storage, { storageItem } from '@/utils/storage';

function SettingsPage() {
  const [storeSecurityCode, setStoreSecurityCode] = useState(storage.getToken(storageItem.storeSecurityCode));
  const [displaySecurityCodeForm, setDisplaySecurityCodeForm] = useState(!!storeSecurityCode);

  const handleSecurityCodeValidation = () => {
    setDisplaySecurityCodeForm(false);
    setStoreSecurityCode(storage.getToken(storageItem.storeSecurityCode));
  };

  return (
    <main>
      <Title>Configura tu tienda:</Title>

      {displaySecurityCodeForm
        && (<SecurityCodeValidation onSuccess={handleSecurityCodeValidation} />)}

      {!storeSecurityCode
        && (<SecurityCode onSuccess={() => setStoreSecurityCode(storage.getToken(storageItem.storeSecurityCode))} />)}

      {(storeSecurityCode
        && !displaySecurityCodeForm) && (<PaymentMethodsForm />)}
    </main>
  );
}

export default SettingsPage;
