type TitleProps = {
  children: React.ReactNode;
};

function Title({ children }: TitleProps) {
  return (
    <h2 className="text-3xl text-center text-primary font-bold my-4 drop-shadow-xl print:hidden">{children}</h2>
  );
}

export default Title;
