import BtnList from '@/components/BtnList';
import useProductNavigation from '@/hooks/useProductNavigation';
import formatMoney from '@/utils/money';
import { formatURL } from '@/utils/url';

type ProductSizeListProps = {
  productSizes: Api.ProductSize[];
};

function ProductSizeList({ productSizes }: ProductSizeListProps) {
  const { selectProductSize } = useProductNavigation();

  const handleSelectProductSize = (size: Api.ProductSize) => {
    selectProductSize(size);
  };

  return (
    <ul className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 max-w-[80%] mx-auto">
      {productSizes.map((size: Api.ProductSize) => (
        <li key={size.id}>
          <BtnList onClick={() => handleSelectProductSize(size)}>
            <div
              className="w-[80%] pb-[80%] bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${formatURL(size.imageUrl)})`,
              }}
            />
            <h2>{`${size.name} ${size.basePrice && `- ${formatMoney(size.basePrice)}`}`}</h2>
          </BtnList>
        </li>
      ))}
    </ul>
  );
}

export default ProductSizeList;
