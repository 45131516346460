import cx from 'classnames';
import { CgSpinnerTwoAlt } from 'react-icons/cg';

type BtnProps = {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  isProcessing?: boolean;
  disabled?: boolean;
};

function Btn({
  className, children, onClick, isProcessing, disabled, type,
}: BtnProps) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={!type ? 'button' : type}
      className={cx('btn', className)}
      onClick={onClick}
      disabled={disabled || isProcessing}
    >
      {children}
      {isProcessing && <CgSpinnerTwoAlt className="animate-spin" />}
    </button>
  );
}

Btn.defaultProps = {
  className: '',
  onClick: () => {},
  isProcessing: false,
  disabled: false,
  type: 'button',
};

export default Btn;
