import { useQuery } from 'react-query';

import { axios } from './axios';

async function getProducts(): Promise<Api.Response<Api.Product[]>> {
  return axios.get('/products');
}

const useProducts = () => {
  const query = useQuery('products', () => getProducts());

  return query;
};

export default useProducts;
