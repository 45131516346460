export const types = {
  setHasConnection: 'cashKeeper/SetHasConnection',
  setCashKeeperConnectionStatus: 'cashKeeper/SetCashKeeperConnectionStatus',
  setCashKeeperStatus: 'cashKeeper/SetCashKeeperStatus',
  handleStartPayment: 'cashKeeper/HandleStartPayment',
  handleEndPayment: 'cashKeeper/HandleEndPayment',
  setOrderInProgress: 'cashKeeper/SetOrderInProgress',
};

export const initialState = {
  isPaying: false,
  cashKeeperConnectionStatus: 'idle',
  cashKeeperStatus: 0,
  hasConnection: false,
  orderInProgress: null,
};

export const reducer = (state: typeof initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case types.setHasConnection:
      return {
        ...state,
        hasConnection: action.payload,
      };
    case types.setCashKeeperConnectionStatus:
      return {
        ...state,
        cashKeeperConnectionStatus: action.payload,
      };
    case types.setCashKeeperStatus:
      return {
        ...state,
        cashKeeperStatus: action.payload,
      };
    case types.handleStartPayment:
      return {
        ...state,
        orderInProgress: action.payload,
        isPaying: true,
      };
    case types.handleEndPayment:
      return {
        ...state,
        isPaying: false,
        orderInProgress: null,
      };
    default:
      return state;
  }
};