/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from '@/components/Title';
import env from '@/utils/constants';
import storage, { storageItem } from '@/utils/storage';

function TokenPage() {
  // eslint-disable-next-line max-len
  const [token, setToken] = useState(env.VITE_APP_VERSION === 'local' ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdG9yZUlkIjoxLCJpYXQiOjE3MTAyMTA1NzQsImV4cCI6MjAyNTc4NjU3NH0.HL4kAWWs0_T0PgSFzt77Z0CUfGL_mk22ckFOafjaN_M' : '');
  const navigate = useNavigate();

  const handleSaveToken = () => {
    storage.setToken(storageItem.token, token);
    navigate('/');
    window.location.reload();
  };

  return (
    <div>
      <div className="bg-primary h-16 flex justify-between items-center px-5">
        <div className="w-full grid place-items-center">
          <img src="/favicon.png" alt="logo" className="w-11 h-11" />
        </div>
      </div>
      <Title>Bienvenido</Title>
      <form
        className="flex flex-col gap-4 items-center bg-white max-w-lg mx-auto px-4 py-8 shadow-lg rounded-lg"
        onSubmit={(e) => {
          e.preventDefault();
          handleSaveToken();
        }}
      >
        <div className="w-full">
          <h2 className="text-xl">Para poder continuar, configura tu tienda</h2>
        </div>
        <span className="p-2 text-xs bg-gray-200 text-gray-700">Lo puedes encontrar en la sección de configuración en el administrador de tu tienda.</span>
        <p>Ingresá el token de tu tienda:</p>
        <div className="relative z-0 w-full mb-5 group">
          <input
            type="text"
            name="floating_token"
            id="floating_token"
            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <label
            htmlFor="floating_token"
            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Token
          </label>
        </div>
        <button
          type="submit"
          className="bg-primary text-white rounded-md px-6 py-2 mt-2"
        >
          Guardar
        </button>
      </form>
    </div>
  );
}

export default TokenPage;
