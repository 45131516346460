import { CartItem } from '@/context/cart/CartContext';
import { axios } from './axios';
import makeMutation from './makeMutation';

interface Order {
  cartItems: Record<string, CartItem>;
  cartTotal: number;
  cartSubTotal: number;
  cartDiscount: number;
  paymentMethod: string;
}

const postOrder = (order: Order) => axios.post('/orders', order);
const mutateOrder = makeMutation(['/orders'], postOrder, ['products']);

const postTransaction = (transaction: {orderId: string, payment: number}) => axios.post('/orders/'+transaction.orderId+'/transaction', { payment: transaction.payment });
const mutateTransaction = makeMutation(['/transactions'], postTransaction, ['products']);

const postOrderCompleted = (orderId: string) => axios.post('/orders/'+orderId+'/success');
const mutateOrderCompleted = makeMutation(['/order/success'], postOrderCompleted, ['products']);

export { mutateOrder, mutateOrderCompleted, mutateTransaction };

