import { ReactNode, useReducer } from 'react';
import {
  CartContext, CartContextInterface, CartItem,
} from './CartContext';
import { cartReducer, initialState, types } from './CartReducer';

interface CartProviderProps {
  children: ReactNode;
}

function CartProvider({ children }: CartProviderProps) {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const handleSetProductToCart = (product: CartItem) => {
    dispatch({ type: types.setProductToCart, payload: product });
  };

  const handleAddItemToCart = (cartItem: CartItem) => {
    dispatch({ type: types.addItemToCart, payload: cartItem });
    dispatch({ type: types.setProductToCart, payload: null });
  };

  const handleRemoveItemFromCart = (productId: string) => {
    dispatch({ type: types.removeItemFromCart, payload: productId });
  };

  const handleUpdateQtyItemCart = (productId: string, quantity: number) => {
    dispatch({ type: types.updateQtyItemCart, payload: { productId, quantity } });
  };

  const handleClearCart = () => {
    dispatch({ type: types.clearCart });
  };

  const orderTotal = Object.values(state.cart).reduce((acc, item) => acc + item.totalPrice, 0);

  const cartItems = Object.values(state.cart).reduce((acc, item) => acc + item.quantity, 0);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValues: CartContextInterface = {
    cart: state.cart,
    productToCart: state.productToCart,
    setProductToCart: handleSetProductToCart,
    addItemToCart: handleAddItemToCart,
    removeItemFromCart: handleRemoveItemFromCart,
    updateQtyItemCart: handleUpdateQtyItemCart,
    clearCart: handleClearCart,
    orderTotal,
    cartItems,
  };

  return (
    <CartContext.Provider value={providerValues}>
      {children}
    </CartContext.Provider>
  );
}

export default CartProvider;
