import useProducts from '@/api/products';
import QueryResult from '@/components/QueryResult';
import Title from '@/components/Title';
import HomeProductsList from '@/features/home/HomeProductsList';

function HomePage() {
  const products = useProducts();

  return (
    <>
      <Title>Selecciona tu Producto</Title>
      <QueryResult query={products}>
        <HomeProductsList products={products.data?.results ?? []} />
      </QueryResult>
    </>
  );
}

export default HomePage;
