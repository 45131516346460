import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Btn from '@/components/Btn';
import Toggle from '@/components/Toggle';
import storage, { storageItem } from '@/utils/storage';
import CashKeeperForm from './CashKeeperForm';

function PaymentMethodsForm() {
  const storePaymentMethods = storage.getTokenAsObject(storageItem.paymentMethods);
  const navigate = useNavigate();

  const [cashPayment, setCashPayment] = useState<boolean>(!!storePaymentMethods?.cashPayment);
  const [cashKeeperPayment, setCashKeeperPayment] = useState<boolean>(!!storePaymentMethods?.cashKeeperPayment);
  const [cardPayment, setCardPayment] = useState<boolean>(!!storePaymentMethods?.cardPayment);

  const handleTogglePaymentMethod = (paymentMethodKey: string) => {
    const updatedPaymentMethods = {
      ...storePaymentMethods,
      [paymentMethodKey]: !storePaymentMethods?.[paymentMethodKey],
    };
    if (paymentMethodKey === 'cashPayment') {
      setCashPayment(!storePaymentMethods?.[paymentMethodKey]);
      if (storePaymentMethods?.[paymentMethodKey]) {
        toast.error('Pago con tarjeta desactivado, no se podrá realizar ventas con tarjeta');
      } else {
        toast.success('Pago con tarjeta activado');
      }
    }
    if (paymentMethodKey === 'cashKeeperPayment') {
      setCashKeeperPayment(!storePaymentMethods?.[paymentMethodKey]);
      if (storePaymentMethods?.[paymentMethodKey]) {
        toast.error('Pago con CashKeeper desactivado, no se podrá realizar ventas con CashKeeper');
      } else {
        toast.success('Pago con CashKeeper activado');
      }
    }
    if (paymentMethodKey === 'cardPayment') {
      setCardPayment(!cardPayment);
    }
    storage.setToken(storageItem.paymentMethods, updatedPaymentMethods);
  };

  const handleCloseSession = () => {
    storage.clear();
    window.location.reload();
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="grid max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-4 gap-4">
        <div className=" mb-4">
          <h2 className="text-2xl">Métodos de pago:</h2>
          <span className="py-2 text-sm text-gray-700">
            Configura los métodos de pago que aceptarás en tu tienda.
          </span>
        </div>
        <div className="flex gap-4">
          <div>
            <Toggle
              checked={cashPayment}
              onChange={() => handleTogglePaymentMethod('cashPayment')}
            />
          </div>
          Pago con tarjeta
        </div>
        <div className="flex gap-4 mt-10">
          <div>
            <Toggle
              checked={cashKeeperPayment}
              onChange={() => handleTogglePaymentMethod('cashKeeperPayment')}
            />
          </div>
          Pago con Cashkeeper
        </div>
        {cashKeeperPayment && <CashKeeperForm />}
        <div className="flex gap-4 mt-10">
          <div>
            <Toggle
              disabled
              checked={cardPayment}
              onChange={() => handleTogglePaymentMethod('cardPayment')}
            />
          </div>
          <h3 className="mb-2 flex gap-2 justify-start items-center">
            Pago con datáfono
            <span className="text-sm text-red-800 bg-red-200 rounded-md p-1">
              Próximamente
            </span>
          </h3>
        </div>
        <div />
      </div>
      <div className="flex flex-col gap-2">
        <Btn
          onClick={() => navigate('/')}
        >
          Ir a la tienda
        </Btn>
        <Btn
          onClick={handleCloseSession}
        >
          Cerrar Sesión
        </Btn>
      </div>
    </div>
  );
}

export default PaymentMethodsForm;
