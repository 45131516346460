import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface OrderState {
  orders: Record<string, Api.Order>;
  lastResetDate: string;
}

interface OrderActions {
  addOrder: (id: string, order: Api.Order) => void;
  orderExists: (id: string) => boolean;
  checkAndResetIfNeeded: () => void;
}

type OrderStore = OrderState & OrderActions;

const getSpainDate = (): string => {
  const now = new Date();
  const spainTime = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Madrid' }));
  return spainTime.toISOString().split('T')[0];
}

const getInitialState = (): OrderState => ({
  orders: {},
  lastResetDate: getSpainDate()
});

const useDailyResetStore = create<OrderStore>()(
  persist(
    (set, get) => ({
      ...getInitialState(),
      addOrder: (id, order) => set((state) => ({
        orders: { ...state.orders, [id]: order }
      })),
      orderExists: (id) => !!get().orders[id],
      checkAndResetIfNeeded: () => {
        const currentSpainDate = getSpainDate();
        if (get().lastResetDate !== currentSpainDate) {
          set({ ...getInitialState(), lastResetDate: currentSpainDate });
        }
      },
    }),
    {
      name: 'daily-reset-order-store',
      getStorage: () => ({
        getItem: (name): string | null => {
          const storedData = localStorage.getItem(name);
          if (storedData) {
            const { state } = JSON.parse(storedData) as { state: OrderState };
            const currentSpainDate = getSpainDate();
            if (state.lastResetDate !== currentSpainDate) {
              return JSON.stringify({ state: getInitialState() });
            }
          }
          return storedData;
        },
        setItem: (name, value) => localStorage.setItem(name, value),
        removeItem: (name) => localStorage.removeItem(name),
      }),
    }
  )
);

export default useDailyResetStore;