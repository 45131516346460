import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import NavBar from '@/components/NavBar';
import Spinner from '@/components/Spinner';
import SettingsPage from '@/pages/SettingsPage';

function App() {
  return (
    <Suspense
      fallback={(
        <div className="h-full w-full flex items-center justify-center">
          <Spinner size="xl" />
        </div>
          )}
    >
      <NavBar />
      <div id="content" className="p-5">
        <Outlet />
      </div>
    </Suspense>
  );
}

const configRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '', element: <SettingsPage /> },
      { path: 'token', element: <Navigate to="/" /> },
    ],
  },
];

export default configRoutes;
