/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';

import { HiOutlineMinusSm, HiOutlinePlusSm } from 'react-icons/hi';
import { MdOutlineIcecream } from 'react-icons/md';

import Btn from '@/components/Btn';
import { CartItem } from '@/context/cart/CartContext';
import ProductModalDelete from '@/features/cart/ProductModalDelete';
import useCart from '@/hooks/useCart';
import formatMoney from '@/utils/money';
import { formatURL } from '@/utils/url';
import { ImCoinEuro } from 'react-icons/im';

function CartPage() {
  const {
    cart, cartItems, orderTotal, updateQtyItemCart,
  } = useCart();
  const products = Object.values(cart);
  const navigate = useNavigate();

  const handleChangeQty = (cartItem: CartItem, action: 'increase' | 'decrease') => {
    updateQtyItemCart(cartItem.id, action === 'increase' ? cartItem.quantity + 1 : cartItem.quantity - 1);
  };

  return (
    <>
      {cartItems > 0 && (
        <>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 max-w-full">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 bg-opacity-50">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Producto
                </th>
                <th scope="col" className="px-6 py-3">
                  Detalles
                </th>
                <th scope="col" className="px-6 py-3 ">
                  <span className="sr-only">Precio</span>
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-900">
              {products.map((product) => (
                <tr className="bg-white bg-opacity-50 border-b" key={product.id}>
                  <td className="px-6 py-4 font-medium text-gray-900">
                    <div className="flex flex-col gap-1 items-center max-w-[30vw]">
                      <img src={formatURL((product?.options?.productSize as Api.ProductSize)?.imageUrl ?? product.product.imageUrl)} alt={product.product.name} className="w-32 h-32 object-contain" />
                      <div className="flex flex-col justify-center text-center">
                        {(product?.options?.productSize as Api.ProductSize)?.name && (
                        <>
                          <h3 className="text-2xl">{product.product.name}</h3>
                          <h2 className="text-xl">{(product?.options?.productSize as Api.ProductSize)?.name}</h2>
                        </>
                        )}
                        {!(product?.options?.productSize as Api.ProductSize)?.name && <h2 className="text-2xl">{product.product.name}</h2>}
                      </div>
                      <ProductModalDelete productId={product.id} />
                    </div>
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900">
                    <div className="flex gap-1 items-center max-w-[40vw]">
                      <div className="flex flex-col justify-center w-full">
                        {Object.entries(product.options).map(([key, values]) => (
                          Array.isArray(values) && (
                          <div className="my-1" key={JSON.stringify(values)}>
                            <p className="block text-center">
                              {product.product.productIngredients?.find((ingredient) => ingredient.id === Number(key))?.name}
                            </p>
                            <div className="flex flex-wrap gap-1 items-center justify-center my-1">
                              {Object.entries(values.reduce((count: { [key: string]: number }, value: number) => {
                                const newCount = count;
                                newCount[value] = (newCount[value] || 0) + 1;
                                return newCount;
                              }, {})).map(([k, v]) => (
                                <div className="bg-primary bg-opacity-45 rounded-full px-3 py-1" key={k + v}>
                                  {product.product.productIngredients?.find((ingredient) => ingredient.id === Number(key))?.productRawMaterials?.find((rawMaterial) => rawMaterial.id === Number(k))?.name}
                                  {v > 1 && (
                                  <span className="text-xs">
                                    x
                                    {v}
                                  </span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                          )
                        ))}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 max-w-[30vw]">
                    <div className="text-xs my-1">
                      Precio
                    </div>
                    <div className="text-xl mb-4">
                      {formatMoney(product?.sizePrice || product.basePrice)}
                    </div>

                    <div className="text-xs my-1">
                      Cantidad
                    </div>
                    <div className="flex items-center mb-4">
                      <button
                        type="button"
                        className="px-3 h-10 bg-gray-200 rounded-l-md hover:bg-gray-400 disabled:opacity-20"
                        onClick={() => handleChangeQty(product, 'decrease')}
                        disabled={product.quantity === 1}
                      >
                        <HiOutlineMinusSm className="text-xl" />
                      </button>
                      <div className="text-2xl bg-gray-200 h-10 px-4 grid place-items-center">
                        {product.quantity}
                      </div>
                      <button
                        type="button"
                        className="px-3 h-10 bg-gray-200 rounded-r-md hover:bg-gray-400"
                        onClick={() => handleChangeQty(product, 'increase')}
                      >
                        <HiOutlinePlusSm className="text-xl" />
                      </button>
                    </div>

                    <div className="text-xs my-1">
                      Subtotal
                    </div>
                    <div className="text-xl mb-4">
                      {formatMoney(product?.totalPrice ?? 0)}
                    </div>

                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="text-xs text-gray-700 uppercase bg-gray-50 bg-opacity-50">
              <tr>
                <th>
                &nbsp;
                </th>
                <th scope="col" className="px-6 py-3">
                  Total
                </th>
                <th scope="col" className="px-6 py-3">
                  <div className="text-3xl">
                    {formatMoney(orderTotal)}
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
          <div className="flex justify-center items-center gap-4 my-8">
            <Btn
            className='h-44 p-10 flex flex-col items-center justify-center gap-2 text-2xl'
            onClick={() => navigate('/')}>
              <span>
                <MdOutlineIcecream className="text-6xl" />
              </span>
              Añadir producto
            </Btn>
            <Btn
              className='h-44 p-10 flex flex-col items-center justify-center gap-2 text-2xl'
              onClick={() => navigate('/checkout')}>
              <span>
                <ImCoinEuro className="text-6xl" />
              </span>
              Continuar con el pago
            </Btn>
          </div>
        </>
      )}
      {cartItems === 0 && (
        <div className="flex flex-col justify-center items-center gap-4 my-8">
          <h1 className="text-3xl">Tu carrito está vacío</h1>
          <Btn onClick={() => navigate('/')}>
            Ir a la tienda
            <MdOutlineIcecream className="text-2xl" />
          </Btn>
        </div>
      )}
    </>
  );
}

export default CartPage;
