import OrderDetailsPrint from './OrderDetailsPrint';

type PrintReceiptProps = {
  order: Api.Order;
  isPrinting: boolean;
};

function PrintReceipt({ order, isPrinting }: PrintReceiptProps) {
  return (
    <div>
      <OrderDetailsPrint
        order={order}
        isPrinting={isPrinting}
      />
    </div>
  );
}

export default PrintReceipt;
