import { useNavigate } from 'react-router-dom';

import { CartItem } from '@/context/cart/CartContext';
import useCart from './useCart';

const useProductNavigation = () => {
  const { productToCart, setProductToCart, addItemToCart } = useCart();
  const navigate = useNavigate();

  const selectProductToCart = (product: Api.Product) => {
    const productTemplate: CartItem = {
      id: `${product.id}-${Math.random()}`, // TODO: replace with uuid
      product,
      options: {},
      quantity: 1,
      basePrice: product.basePrice ?? 0,
      sizePrice: false,
      additionalPrice: 0,
      totalPrice: product.basePrice ?? 0,
      order: 0,
      displayOrder: 0,
    };
    setProductToCart(productTemplate);
    if (productTemplate.product?.productSizes?.length === 0
      && productTemplate.product?.productIngredients?.length === 0) {
      addItemToCart(productTemplate);
      navigate('/cart', { replace: true });
      return;
    }
    if (productTemplate.product?.productSizes?.length > 0) {
      navigate('/product/size');
      return;
    }
    if (productTemplate.product?.productIngredients?.length > 0) {
      navigate(`/product/ingredient/${productTemplate.product.productIngredients[0].id}`);
    }
  };

  const selectProductSize = (productSize: Api.ProductSize) => {
    if (!productToCart) {
      throw new Error('Product not found');
    }
    const productTemplate = { ...productToCart };
    productTemplate.options = {
      ...productTemplate.options,
      productSize,
    };
    productTemplate.sizePrice = productSize.basePrice ?? productTemplate.basePrice;
    productTemplate.totalPrice = productSize.basePrice ?? productTemplate.basePrice;
    setProductToCart(productTemplate);
    if (productTemplate.product?.productIngredients?.length > 0) {
      navigate(`/product/ingredient/${productTemplate.product.productIngredients[0].id}`);
      return;
    }
    addItemToCart({
      ...productTemplate,
      basePrice: productSize.basePrice ?? 0,
      totalPrice: productSize.basePrice ?? 0,
    });
    navigate('/cart', { replace: true });
  };

  const selectProductIngredient = (productIngredient: Api.ProductIngredient, productRawMaterials: number[]) => {
    if (!productToCart) {
      throw new Error('Product not found');
    }
    const productTemplate = { ...productToCart };
    productTemplate.options[productIngredient.id] = productRawMaterials;
    setProductToCart(productTemplate);

    const isLastIngredientStep = productTemplate.product?.productIngredients?.indexOf(productIngredient) === (productTemplate.product?.productIngredients?.length ?? 0) - 1;
    if (isLastIngredientStep) {
      addItemToCart(productTemplate);
      navigate('/cart', { replace: true });
      return;
    }
    const nextIngredient = productTemplate.product?.productIngredients[(productTemplate.product?.productIngredients?.indexOf(productIngredient) ?? 0) + 1];
    navigate(`/product/ingredient/${nextIngredient.id}`);
  };

  return {
    productToCart,
    selectProductToCart,
    selectProductSize,
    selectProductIngredient,
  };
};

export default useProductNavigation;
