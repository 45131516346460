import mergePaymentMethods from '@/utils/mergePaymentMethods';
import { Store } from './AuthContext';

export const types = {
  handleLogin: 'auth/HandleLogin',
  handleLogout: 'auth/HandleLogout',
  setDevicePaymentMethods: 'auth/SetDevicePaymentMethods',
};

export const initialState = {
  isLoading: 'idle',
  store: {
    token: null,
    paymentMethods: [],
    devicePaymentMethods: {},
  } as Store,
};

export const reducer = (state: typeof initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case types.handleLogin:
      return {
        ...action.payload,
        isLoggedIn: true,
      };
    case types.handleLogout:
      return {
        isLoggedIn: false,
      };
    case types.setDevicePaymentMethods:
      return {
        ...state,
        store: {
          ...state.store,
          devicePaymentMethods: mergePaymentMethods(action.payload, state.store.paymentMethods),
        },
      };
    default:
      return state;
  }
};
