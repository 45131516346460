import cx from 'classnames';


type OrderDetailsPrintProps = {
  order: Api.Order;
  isPrinting: boolean;
};

function OrderDetailsPrint({
  order,
  isPrinting,
}: OrderDetailsPrintProps) {
  return (
    <div
      className={cx('hidden', {
        'print:block': isPrinting,
      })}>
      <h4 className="text-black font-serif text-7xl">{`Pedido #${order.dailyOrderNumber}`}</h4>
    </div>
  );
}

export default OrderDetailsPrint;
