/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import toast from 'react-hot-toast';

import Btn from '@/components/Btn';
import storage, { storageItem } from '@/utils/storage';

type SecurityCodeValidationProps = {
  onSuccess: () => void;
};

function SecurityCodeValidation({ onSuccess }: SecurityCodeValidationProps) {
  const [securityCode, setSecurityCode] = useState('');

  const handleConfirmSecurityCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sc = storage.getToken(storageItem.storeSecurityCode);
    if (sc !== securityCode) {
      toast.error('Código de seguridad incorrecto');
      return;
    }
    onSuccess();
  };

  return (
    <div className="grid max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-4 gap-4">
      <form onSubmit={handleConfirmSecurityCode}>
        <div className="mb-4">
          <h2 className="text-2xl">Seguridad:</h2>
          <span className="py-2 text-sm text-gray-700">
            Por favor, ingresa el código de seguridad de tu tienda.
          </span>
          <div className="relative z-0 w-full my-5 group">
            <input
              type="text"
              name="floating_securityCode"
              id="floating_securityCode"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              value={securityCode}
              onChange={(e) => setSecurityCode(e.target.value)}
            />
            <label
              htmlFor="floating_securityCode"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Código de seguridad
            </label>
          </div>
        </div>
        <Btn
          type="submit"
          disabled={!securityCode}
        >
          Continuar
        </Btn>
      </form>
    </div>
  );
}

export default SecurityCodeValidation;
