import { createContext } from 'react';

export interface CartItem {
  id: string;
  product: Api.Product; // product based on
  options: Record<string | number, Api.ProductSize | number[]>; // options selected
  quantity: number;
  basePrice: number;
  sizePrice: number | false;
  additionalPrice: number;
  totalPrice: number;
  order: number;
  displayOrder: number;
}

export interface CartContextInterface {
  cart: Record<string, CartItem>;
  addItemToCart: (cartItem: CartItem) => void;
  productToCart: CartItem | null;
  setProductToCart: (product: CartItem) => void;
  removeItemFromCart: (productId: string) => void;
  updateQtyItemCart: (productId: string, qty: number) => void;
  orderTotal: number;
  clearCart: () => void;
  cartItems: number;
}

export const cartContextDefaultValues: CartContextInterface = {
  cart: {},
  productToCart: null,
  setProductToCart: () => null,
  addItemToCart: () => null,
  removeItemFromCart: () => null,
  updateQtyItemCart: () => null,
  orderTotal: 0,
  clearCart: () => null,
  cartItems: 0,
};

export const CartContext = createContext<CartContextInterface>(cartContextDefaultValues);
