import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Title from '@/components/Title';
import ProductSizeList from '@/context/product/ProductSizeList';
import useCart from '@/hooks/useCart';
import useProductNavigation from '@/hooks/useProductNavigation';

function ProductSizePage() {
  const { productToCart } = useCart();
  const { selectProductSize } = useProductNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productToCart) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (productToCart?.product.productSizes.length === 1) {
      if (productToCart.options.productSize === undefined) { // this means that the user has not selected a size yet
        selectProductSize(productToCart.product.productSizes[0]);
      } else { // this means that the user has already selected a size and the back button was pressed so we need to navigate to home
        navigate('/');
      }
    }
  }, [productToCart]);

  return (
    <>
      <Title>Selecciona tu tamaño</Title>
      <ProductSizeList productSizes={productToCart?.product.productSizes ?? []} />
    </>
  );
}

export default ProductSizePage;
