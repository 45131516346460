import { DevicePaymentMethod } from '@/context/auth/AuthContext';

const mergePaymentMethods = (paymentMethods: DevicePaymentMethod[], paymentMethodsOnDevice: Api.PaymentMethod[]) => {
  if (!paymentMethods) {
    // eslint-disable-next-line no-param-reassign
    paymentMethods = [];
  }
  if (!paymentMethodsOnDevice) {
    // eslint-disable-next-line no-param-reassign
    paymentMethodsOnDevice = [];
  }
  return paymentMethodsOnDevice;
};

export default mergePaymentMethods;
