import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from '@/components/NavBar';
import Spinner from '@/components/Spinner';
import CartPage from '@/pages/CartPage';
import CheckoutPage from '@/pages/CheckoutPage';
import HomePage from '@/pages/HomePage';
import SettingsPage from '@/pages/SettingsPage';
import ProductIngredientPage from '@/pages/product/ProductIngredientPage';
import ProductSizePage from '@/pages/product/ProductSizePage';

function App() {
  return (
    <Suspense
      fallback={(
        <div className="h-full w-full flex items-center justify-center">
          <Spinner size="xl" />
        </div>
          )}
    >
      <NavBar />
      <div id="content" className="p-5">
        <Outlet />
      </div>
    </Suspense>
  );
}

const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: 'product/size', element: <ProductSizePage /> },
      { path: 'product/ingredient/:ingredientId', element: <ProductIngredientPage /> },
      { path: 'config', element: <SettingsPage /> },
      { path: 'cart', element: <CartPage /> },
      { path: 'checkout', element: <CheckoutPage /> },
      // { path: 'token', element: <Navigate to="." /> },
      { path: '*', element: <p>Not found</p> },
    ],
  },
];

export default protectedRoutes;
