import { useRoutes } from 'react-router-dom';

import useAuth from '@/hooks/useAuth';
import storage, { storageItem } from '@/utils/storage';
import configRoutes from './configRoutes';
import protectedRoutes from './protectedRoutes';
import publicRoutes from './publicRoutes';

const getLoggedInRoutes = () => {
  const securityCode = storage.getToken(storageItem.storeSecurityCode);
  if (!securityCode) {
    return configRoutes;
  }
  const paymentMethods = storage.getTokenAsObject(storageItem.paymentMethods) || {};
  const hasPaymentMethodsEnabled = Object.values(paymentMethods).some((value) => value === true);
  if (!hasPaymentMethodsEnabled) {
    return configRoutes;
  }
  return protectedRoutes;
};

export function AppRoutes() {
  const auth = useAuth();

  const { isLoggedIn } = auth;

  const routes = [];

  if (isLoggedIn === true) {
    routes.push(...getLoggedInRoutes());
  }

  if (isLoggedIn !== true) {
    routes.push(...publicRoutes);
  }

  const element = useRoutes([...routes]);

  return element;
}

export default AppRoutes;
