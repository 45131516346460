import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { HiArrowRight, HiCheck } from 'react-icons/hi';

import Btn from '@/components/Btn';
import BtnList from '@/components/BtnList';
import useCart from '@/hooks/useCart';
import useProductNavigation from '@/hooks/useProductNavigation';

function ProductIngredientPage() {
  const { ingredientId } = useParams();
  const { productToCart } = useCart();
  const { selectProductIngredient } = useProductNavigation();
  const navigate = useNavigate();
  const [productRawMaterialsSelected, setProductRawMaterialsSelected] = useState<number[]>([]);

  useEffect(() => {
    if (!productToCart) {
      navigate('/');
    }
  }, []);

  const productIngredient = productToCart?.product.productIngredients.find((ingredient) => ingredient.id === Number(ingredientId));
  const productIngredientLimit = productIngredient?.maxOptions.find((maxOption) => maxOption.sizeId === Number((productToCart?.options?.productSize as Api.ProductSize)?.id))?.maxOptions ?? 1;

  const handleSelectProductRawMaterial = (productRawMaterialId: number) => {
    const newProductIngredientsSelected = [...productRawMaterialsSelected, productRawMaterialId];
    if (productRawMaterialsSelected.length === productIngredientLimit) {
      newProductIngredientsSelected.shift();
    }
    setProductRawMaterialsSelected(newProductIngredientsSelected);
  };

  const handleSelectProductIngredient = () => {
    if (!productIngredient) {
      return;
    }
    setProductRawMaterialsSelected([]);
    selectProductIngredient(productIngredient, productRawMaterialsSelected);
  };

  return (
    <>
      <h2 className="text-3xl text-center text-primary font-bold my-4">{productIngredient?.name}</h2>
      {productIngredientLimit > 1 && (
        <h4 className="text-center font-bold text-xl text-gray-900">
          Puedes seleccionar hasta
          {' '}
          {productIngredientLimit}
          {' '}
          opciones
        </h4>
      )}
      <ul className={cx(
        'grid place-content-center gap-5',
        (productIngredient?.productRawMaterials?.length ?? 0) < 4 ? 'grid-cols-2' : 'grid-cols-4',
      )}
      >
        {productIngredient?.productRawMaterials.map((productRawMaterial: Api.ProductRawMaterial) => (
          <li key={productRawMaterial.id}>
            <BtnList onClick={() => handleSelectProductRawMaterial(productRawMaterial.id)}>
              <div
                className="w-[80%] pb-[80%] bg-contain bg-no-repeat bg-center relative"
                style={{
                  backgroundImage: `url(${productRawMaterial.imageUrl})`,
                }}
              >
                <span className={cx(
                  'absolute top-0 left-0 bg-green text-white w-8 h-8 place-items-center rounded-lg',
                  productRawMaterialsSelected.includes(productRawMaterial.id) ? 'grid' : 'hidden',
                )}
                >
                  {productIngredientLimit > 1 && (
                    <>
                      {productRawMaterialsSelected.reduce((acc, curr) => (curr === productRawMaterial.id ? acc + 1 : acc), 0)}
                    </>
                  )}
                  {productIngredientLimit === 1 && (
                    <HiCheck />
                  )}
                </span>
              </div>
              <h2>{productRawMaterial.name}</h2>
            </BtnList>
          </li>
        ))}
      </ul>
      {productRawMaterialsSelected.length > 0 && (
        <Btn
          className="fixed bottom-4 right-4"
          onClick={handleSelectProductIngredient}
        >
          Continuar
          <HiArrowRight className="ml-2" />
        </Btn>
      )}
    </>
  );
}

export default ProductIngredientPage;
