import { CartItem } from './CartContext';

export const types = {
  addItemToCart: 'cart/AddItemToCart',
  setProductToCart: 'cart/SetProductToCart',
  removeItemFromCart: 'cart/RemoveItemFromCart',
  updateQtyItemCart: 'cart/UpdateQtyItemCart',
  clearCart: 'cart/ClearCart',
};

export const initialState = {
  cart: {} as Record<string, CartItem>,
  productToCart: null,
  cartTotal: 0,
  cartSubTotal: 0,
  cartDiscount: 0,
};

export const cartReducer = (state: typeof initialState, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case types.addItemToCart: {
      const lastCartItem = Object.values(state.cart).pop();
      const lastOrder = lastCartItem ? lastCartItem.displayOrder : 0;
      return {
        ...state,
        cart: {
          ...state.cart,
          [action.payload.id]: {
            ...action.payload,
            displayOrder: lastOrder + 10,
          },
        },
      };
    }
    case types.setProductToCart:
      return {
        ...state,
        productToCart: action.payload,
      };
    case types.removeItemFromCart: {
      const newCart: Record<string, CartItem> = { ...state.cart };
      delete newCart[action.payload];
      return {
        ...state,
        cart: newCart,
      };
    }
    case types.updateQtyItemCart: {
      const newCart: Record<string, CartItem> = { ...state.cart };
      newCart[action.payload.productId].quantity = action.payload.quantity;
      newCart[action.payload.productId].totalPrice = (newCart[action.payload.productId].sizePrice || newCart[action.payload.productId].basePrice) * action.payload.quantity;
      return {
        ...state,
        cart: newCart,
      };
    }
    case types.clearCart:
      return {
        ...state,
        cart: {},
      };
    default:
      return state;
  }
};
