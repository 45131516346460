import BtnList from '@/components/BtnList';
import useProductNavigation from '@/hooks/useProductNavigation';
import { formatURL } from '@/utils/url';

function HomeProductsList({ products }: { products: Api.Product[] }) {
  const { selectProductToCart } = useProductNavigation();

  const handleSelectProduct = (product: Api.Product) => {
    selectProductToCart(product);
  };

  return (
    <ul className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 max-w-[80%] mx-auto print:hidden">
      {products.map((product) => (
        <li key={product.id}>
          <BtnList onClick={() => handleSelectProduct(product)}>
            <div
              className="w-[80%] pb-[80%] bg-contain bg-no-repeat bg-center"
              style={{
                backgroundImage: `url(${formatURL(product.imageUrl)})`,
              }}
            />
            <h2>{product.name}</h2>
          </BtnList>
        </li>
      ))}
    </ul>
  );
}

export default HomeProductsList;
